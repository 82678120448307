table.column-hover {
  @for $i from 0 through 10 {
    &.active-col-#{$i} [data-col-idx='#{$i}'] {
      @apply bg-light-gray;

      cursor: pointer;

      & > .visible-on-hover {
        visibility: visible !important;
      }
    }
  }
}
