div.toast {
  @apply p-s;

  &Wrapper {
    @apply border border-outline-primary;
  }

  &Container {
    @apply w-full;

    padding: 0 10%;
    margin-top: 100px;

    @screen md {
      @apply w-4/5 mx-auto p-0;

      margin-top: 149px;
      max-width: 600px;
    }
  }
}
