// TODO refactor Guidance's code
// replace and remove this place, should use css modules instead

.checkout {
  h1 {
    font-size: 22px;
  }

  input {
    &.form {
      border: none;
      border-bottom: 1px solid var(--outline-primary);
      padding-left: 0;
      padding-right: 0;
      color: var(--black);
      background-color: unset;

      &:hover,
      &:focus {
        border: none;
        border-bottom: 1px solid var(--outline-primary);
        padding-left: 0;
        padding-right: 0;
        box-shadow: none;
      }
    }
  }
}

.card {
  background-color: var(--grey-100);
}

.error {
  color: var(--red-100);
}

.bberror {
  border-bottom-color: var(--red-300) !important;
}

.bluelink {
  color: var(--purple);
}

.noborder-bottom {
  border: 1px 1px 0 solid var(--outline-primary);
}

.delivery-label {
  line-height: 56px;
}

.border-bottom {
  border-bottom: 1px solid var(--outline-primary);
}

.react-tel-input .flag-dropdown {
  top: 18px !important;
  height: 30px;
  border: none !important;
  background-color: inherit;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
}

.react-tel-input .form-control {
  border: none !important;
  height: 25px;
  background: transparent !important;
}

.country-list {
  cursor: pointer;
}
