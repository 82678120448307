@use '@assets/mixins/typography';

.heading1 {
  @include typography.text('heading-1');
}

.heading2 {
  @include typography.text('heading-2');
}

.heading3 {
  @include typography.text('heading-3');
}

.heading4 {
  @include typography.text('heading-4');
}

.heading5 {
  @include typography.text('heading-5');
}

.text1 {
  @include typography.text('text-1');
}

.text2 {
  @include typography.text('text-2');
}

.text3 {
  @include typography.text('text-3');
}

.text4 {
  @include typography.text('text-4');
}

.xsmall {
  @include typography.text('xsmall');
}

.medium {
  @include typography.text('medium');
}

.base {
  @include typography.text('base');
}

.large {
  @include typography.text('large');
}

.basebold {
  @include typography.text('base-bold');
}

.header5 {
  @include typography.text('header-5');
}

.header5book {
  @include typography.text('header-5-book');
}

.header4 {
  @include typography.text('header-4');
}

.header3 {
  @include typography.text('header-3');
}

.header2 {
  @include typography.text('header-2');
}

.header1 {
  @include typography.text('header-1');
}

.eyebrow {
  @include typography.text('eyebrow');
}

.html {
  a {
    @apply text-cta;
  }
}
