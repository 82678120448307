:root {
  /* Primary */
  --eggplant: #240047;
  --purple: #8652ff;
  --dark-purple: #562e9b;
  --purple-hover: #653ebf;
  --dark-lilac: #643bc1;
  --violet: #c1a6ff;
  --lilac: #e9deff;
  --lilac-30: #f4eeff;
  --white: #fff;
  --gray: #f4f4f4;

  /* NPI 2024 colors */
  --life360-purple: var(--purple);
  --sidewalk-stone: #f4f0e7;
  --sneaker-white: var(--white);
  --midnight-purple: var(--purple-midnight);
  --dusk: #5f3ab5;
  --dawn: #d4c2ff;
  --light: var(--lilac);
  --poolside-teal: #01c9d9;
  --park-green: #c8e802;
  --school-bus-yellow: #ffce19;
  --sunset-pink: #f9c2ff;
  --navy-24: #002554;
  --coral-24: #f87c56;
  --blue-24: #74d1ea;
  --green-24: #006747;
  --dark-blue-24: #1ba8d0;
  --yellow-24: #ffed47;
  --magenta-24: #ce0f69;
  --pink-24: #ffade2;

  /* Secondary */
  --sky: #75e6ff;
  --throwback: #00adbb;
  --mint: #02e8bf;
  --purple-midnight: #311969;
  --midnight: #092c45;
  --caribbean: #01656d;
  --evergreen: #034a3e;
  --evergreenv3: #005b62;

  /* Tertiary */
  --protocol: #f0e757;
  --orange: #ff9548;
  --orchid: #e366c8;
  --olive: #444000;
  --clay: #783200;
  --burgundy: #5b0025;
  --mirage: #191931;
  --heliotrope: #f492ff;
  --bubblegum: #f6bcd2;
  --pepper: #af2913;
  --electric-red: #de3014;
  --electric-green: #8fc14e;
  --ceramic-blue: #d8e8e2;
  --moss-grey: #243832;
  --clear-caribbean: #086579;
  --dark-mirage: #1c1c40;
  --tidepool-teal: #b3eff4;

  /* Utility */
  --bone: #fffcf8;
  --gray-50: #666;
  --gray-40: #999;
  --gray-30: #bebebe;
  --gray-20: #ccc;
  --gray-10: #efefef;
  --gray-5: #f5f5f5;
  --gray-200: #f8f8f8;
  --black: #1d1d1d;
  --pitch-black: #000;

  /* State Colors */
  --green-50: #01846d;
  --green-100: #00af66;
  --yellow-50: #fff550;
  --yellow-100: #ffbd52;
  --red-100: #e94641;
  --orange-100: #f57c13;

  /* Var References */
  --primary: var(--white);
  --primary-2: var(--gray-10);
  --secondary: var(--black);
  --secondary-2: var(--gray-50);
  --light-gray: var(--gray-5);

  /* Text */
  --text-base: var(--black);
  --text-primary: var(--black);
  --text-secondary: var(--black);
  --text-secondary-2: var(--gray-50);
  --text-disabled: var(--gray-50);
  --text-placeholder: var(--gray-50);

  /* Outline */
  --outline-primary: var(--gray-20);
  --outline-selected: var(--cta);

  /* CTA */
  --cta: var(--purple);
  --cta-light: var(--lilac);
  --hover-cta: var(--purple-hover);

  /* Error States */
  --danger: var(--yellow-100);
  --error: var(--red-100);

  /* Opacities */
  --selected: var(--gray-5);
  --disabled: rgba(216, 216, 216, 0.3);

  /* Base Colors */
  --gold: #f9ab00;
  --blue: #4e37fa;
  --cyan: #22b8cf;
  --green-dark: #0c7074;

  /* Shades */
  --grey-100: #f6f6f6;
  --grey-400: #abaeb1;
  --grey-600: #757575;
  --grey-700: #5f646a;
  --grey-750: #444;
  --grey-800: #4a4a4a;
  --red-200: #d60d0d;
  --red-300: #bc0606;
  --accents-0: #f8f9fa;
  --accents-1: #f1f3f5;
  --accents-2: #e9ecef;
  --accents-3: #dee2e6;
  --accents-4: #ced4da;
  --accents-6: #868e96;
  --accents-7: #495057;
  --accents-8: #343a40;
  --accents-9: #212529;
  --accents-10: #546e7a;
  --black-300: #a5a5a5;
  --black-500: #4a4a4a;
  --black-400: #777;
  --black-600: #111;
  --contrasted: #0a7e80;
  --green-200: #4aac42;
  --blue-600: #3f50e5;
  --blue-700: #170731;
  --blue-900: #19205c;
  --light-blue-400: #acf0ff;
  --mintgreen-200: #ccfaf2;

  /* Fonts */
  --font-primary: 'Twemoji Country Flags', 'CentraNo2';
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings:
    'case' 1,
    'rlig' 1,
    'calt' 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-family: var(--font-primary);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  color: var(--text-primary);
  color-scheme: normal;
}

* {
  font-family: var(--font-primary);
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

/* to remove chrome default autofill styling */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px var(--primary) inset;
  -webkit-text-fill-color: var(--text-primary);
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

b,
strong {
  font-weight: 700 !important;
}

em {
  font-style: italic;
}

.menu-icon {
  font-size: 21px;
}

.mobile-menu-open {
  overflow: hidden;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: unset !important;
}

.swiper-button-disabled {
  filter: grayscale(100%) opacity(0.5) !important;
}

.osano-cm-widget {
  display: none;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

iframe[hidden] {
  display: none;
}

/** set all user-interactable elements font-size to 16px, to prevent iOS auto zoom
  * once site typography for mobile is updated to min 16px, remove this
  */
select,
select:focus,
textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'] {
  @media screen and (width <= 768px) {
    font-size: 16px !important;
  }
}
