@use 'sass:map';
@use 'sass:list';
@use 'sass:string';

// clean up all typography once everything is updated to new design
$heading-font-sizes: (
  '1': (
    'desktop': 44px,
    'mobile': 28px,
  ),
  '2': (
    'desktop': 36px,
    'mobile': 24px,
  ),
  '3': (
    'desktop': 22px,
    'mobile': 18px,
  ),
  '4': (
    'desktop': 20px,
    'mobile': 16px,
  ),
  '5': (
    'desktop': 18px,
    'mobile': 14px,
  ),
);
$text-font-sizes: (
  '1': (
    'desktop': 20px,
    'mobile': 16px,
  ),
  '2': (
    'desktop': 18px,
    'mobile': 14px,
  ),
  '3': (
    'desktop': 16px,
    'mobile': 12px,
  ),
  '4': (
    'desktop': 14px,
    'mobile': 12px,
  ),
);

// new typography, following figma (but in kebab-case)
// temporarily it'll be used together with old typography
$typography: (
  'xxsmall': (
    'font-size': 9.6px,
    'line-height': 11.52px,
    'font-weight': 400,
  ),
  'xsmall': (
    'font-size': 12px,
    'line-height': 14.4px,
    'font-weight': 400,
  ),
  'medium': (
    'font-size': 14.4px,
    'line-height': 17.28px,
    'font-weight': 400,
  ),
  'base-bold': (
    'font-size': 16px,
    'line-height': 20px,
    'font-weight': 700,
  ),
  'base': (
    'font-size': 16px,
    'line-height': 20px,
    'font-weight': 400,
  ),
  'large': (
    'font-size': 18px,
    'line-height': 20px,
    'font-weight': 400,
  ),
  'header-5-book': (
    'font-size': 21.28px,
    'line-height': 29.79px,
    'font-weight': 400,
  ),
  'header-5': (
    'font-size': 21.28px,
    'line-height': 26.6px,
    'font-weight': 700,
  ),
  'header-4': (
    'font-size': 28.32px,
    'line-height': 33.98px,
    'font-weight': 700,
  ),
  'header-3': (
    'font-size': 37.9px,
    'line-height': 41.69px,
    'font-weight': 700,
  ),
  'header-2': (
    'font-size': 50.4px,
    'line-height': 60.48px,
    'font-weight': 700,
  ),
  'header-1': (
    'font-size': 67.34px,
    'line-height': 77.45px,
    'font-weight': 700,
  ),
  'eyebrow': (
    'font-size': 16px,
    'line-height': 16px,
    'font-weight': 700,
  ),
);

@function str-split($string, $separator) {
  $i: string.index($string, $separator);

  @if $i {
    @return list.append(
      string.slice($string, 1, $i - 1),
      str-split(string.slice($string, $i + string.length($separator)), $separator)
    );
  }

  @return $string;
}

@function get-sizes($type) {
  @if $type == 'heading' {
    @return $heading-font-sizes;
  }

  @return $text-font-sizes;
}

@mixin base($type: text) {
  @if $type == text {
    @apply leading-normal font-normal text-secondary;
  } @else if $type == heading {
    @apply font-bold text-primary;

    line-height: 1.2;
  }
}

@mixin text($variant: text) {
  $config: str-split($variant, '-');
  $type: list.nth($config, 1);

  // old typography
  @if $type == 'heading' or $type == 'text' {
    $size: list.nth($config, 2);
    $sizes: get-sizes($type);

    @include base($type);

    @if $variant == 'heading-5' {
      @apply font-semibold;
    }

    font-size: map.get(map.get($sizes, $size), 'mobile');

    @screen md {
      font-size: map.get(map.get($sizes, $size), 'desktop');
    }
  } @else {
    // new typography
    $mapping: map.get($typography, $variant);

    font-size: map.get($mapping, 'font-size');
    font-weight: map.get($mapping, 'font-weight');
    line-height: map.get($mapping, 'line-height');
  }
}
