.root {
  @apply py-10 px-xl;

  @screen lg {
    padding: 90px 155px;
  }

  a {
    @apply text-cta underline;
  }
}
